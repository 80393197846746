/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 96%;
}
#home {
  width: 380px;
}
/*.section--content {
  padding-bottom: (70px + 40px);  
}

.section--footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 220;
  margin-top: 0;
}*/
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
div.north {
  left: 5%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
#services {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-large.css.map */